
html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  background: var(--bg-flat) linear-gradient(180deg, var(--bg-gradient-top) 67%, var(--bg-gradient-bottom) 100%);
  background-attachment: fixed;
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", BlinkMacSystemFont,
    -apple-system,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

#app {
  margin: 0 auto;
  height: 100%;
}

body {
  color: var(--text-normal);
}

body .container-fluid {
  display: flex;
  flex-direction: column;
  padding: 0;
}

body .container-fluid.nav-menu-closed {
  min-height: 100%;
}

.container-fluid .content {
  margin: 0 5%;
  flex: 1;
}

p {
  color: var(--text-normal);
}

textarea, textarea.form-control {
  color: var(--input-text);
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
}

textarea:focus,
textarea.form-control:focus {
  color: var(--input-text);
  background-color: var(--input-bg);
}

.pointer {
  cursor: pointer;
}

.transparent {
  color: transparent !important;
}

span.propname {
  font-weight: bold;
}

span.hl {
  color: var(--text-highlight);
  background-color: var(--text-highlight-bg);
}

span.hl-key {
  color: #000;
  padding: 0 4px;
  font-weight: bold;
  background-color: var(--text-highlight-key);
  border: 2px solid var(--text-highlight-key-border);
}

hr.thinhr {
  margin: 4px 0 2px 0;
}

button.btn.btn-primary, 
input.btn.btn-primary, 
.btn-group button.btn-primary {
  min-width: 136px;
  height: 36px;
  border-radius: 0;
  border: 0;
  color: var(--button-primary-text);
  background-color: var(--button-primary-bg);
}

button.btn .material-icons,
input.btn .material-icons {
  font-size: 16px;
  margin-right: 8px;
}

button.btn.btn-primary:hover,
button.btn.btn-primary:active, 
input.btn.btn-primary:hover, 
input.btn.btn-primary:active {
  color: var(--button-primary-text);
  background-color: var(--button-primary-active-bg);
}

button.btn.btn-secondary, 
input.btn.btn-secondary,
.btn-group button.btn-secondary {
  min-width:86px;
  height: 36px;
  border-radius: 0;
  border: 1px solid var(--button-secondary-border);
  color: var(--button-secondary-text);
  background-color: var(--button-secondary-bg);
}

button.btn.btn-secondary:hover,
button.btn.btn-secondary:active, 
input.btn.btn-secondary:hover, 
input.btn.btn-secondary:active {
  color: var(--button-secondary-text);
  background-color: var(--button-secondary-active-bg);
}

.btn-group button.btn-primary, .btn-group button.btn-secondary {
  padding: 0;
  height: 40px;
}

div.btn-group-toolbar {
  padding: 0;
  /* border: 1px solid var(--button-secondary-border); */
}

div.btn-group-toolbar div.btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.btn-group-btn.btn-secondary:hover {
  /* border-color: var(--button-secondary-border); */
  /* background-color: var(--table-hover-row-bg); */
  opacity: 0.5;
}

button.btn-group-btn.btn-secondary:active,
button.btn-group-btn.btn-primary:active {
  /* border-color: var(--button-secondary-border); */
  /* background-color: transparent; */
  opacity: 0.75;
}

button.btn-group-btn.btn-primary {
  /* border: 1px solid var(--button-secondary-border);
  background-color: var(--button-secondary-border); */
  opacity: 1;
} 

button.btn-group-btn.btn-secondary {
  /* background-color: var(--table-hover-row-bg); */
  border-color: transparent;
  opacity: 0.35;
}

button.btn.btn-link {
  color: var(--link);
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-align: left;
  border: 0;
}

button.btn.btn-link.link-underline {
  color: var(--link);
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

button.btn.btn-link:hover {
  color: var(--link-active);
}

button.btn-with-icon {
  display: flex;
  align-items: center;
}

h1 {
  color: var(--text-normal);
  font-size: 26px;
  line-height: 33px;
  font-weight: 400;
}

h2, h3, h4, h5, h6 {
  color: var(--text-normal);
}

.dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid var(--submenu-border);
  box-shadow: 4px 4px 10px var(--shadow-1);
  border-radius: 3px;
}

.dropdown-menu .dropdown-item {
  padding: .75em .75em;
  color: var(--submenu-text);
  background-color: var(--submenu-bg);
}

.dropdown-menu .dropdown-item:hover {
  color: var(--submenu-active-text);
  background-color: var(--submenu-active-bg);
}

.pointer {
  cursor: pointer;
}

.code-block {
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  font-style: normal;
}

.claim-row-label {
  display: inline-block;
  width: 150px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-medium);
  margin-bottom: 10px;
}

.claim-row-value {
  display: inline-block;
  flex: 1;
  max-width: 300px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-normal);
  margin-bottom: 10px;
}

.form-check-input:checked {
  border-color: var(--button-primary-bg);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
}

.disabled-look, .form-control:disabled {
  cursor: default !important;
  color: var(--text-disabled) !important;
  background-color: inherit;
  opacity: inherit;
}

.form-group.disabled-look {
  opacity: 0.5;
}

.display-flex {
  display: flex;
}

.hide-me {
  display: none;
}

div.search-form {
  display: flex;
  flex-wrap: wrap; 
  flex-basis: max-content;
  row-gap: 5px;
  column-gap: 10px;
}

div.search-form-action-row {
  display: flex;
  margin-top: 10px;
  column-gap: 10px;
}

div.grid-search-form-action-row {
  display: flex;
  margin: 20px -20px 0 -20px;
  padding: 18px 20px 0 20px;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  border-top: 1px solid var(--elevated-border2);
}

div.grid-search-form-action-row input[type=submit] {
  width: 106px;
  min-width: 106px;
}

@media only screen and (max-width: 767px) {
  div.grid-search-form-action-row input[type=submit] {
      width: 138px;
      min-width: 138px;
    }
}

div.grid-search-form-action-row input[type=reset] {
  width: 66px;
  min-width: 66px;
  padding: 0;
  margin: 0;
}

div.effective-date-range {
  display: flex;
  align-items: baseline;
  row-gap: 5px;
  column-gap: 10px;
}

span.parser-control-char {
  display: inline-block;
  position: relative;
  top: -2px;
  font-family: courier;
  font-size: 14px;
  padding: 1px 4px;
  border-radius: 4px;
}

span.parser-field-hover:hover {
  cursor: pointer;
  border-color: var(--table-hover-row-border);
}

.app-tooltip {
  box-shadow: 2px 2px 8px var(--menu-subheader-text) !important;
  border: 1px solid var(--menu-subheader-text) !important;
  background-color: var(--menu-subheader-text) !important;
  color: var(--text-normal) !important;
  font-size: 16px !important;
  line-height: 20px !important;
  pointer-events: auto !important;
  max-width: 600px !important;
  white-space: pre-line;
  opacity: 1 !important;
}

.app-tooltip.place-left::after {
  border-left: 12px solid var(--menu-subheader-text) !important;
}

.app-tooltip.place-right::after {
  border-right: 12px solid var(--menu-subheader-text) !important;
}

.app-tooltip.place-top::after {
  border-top: 12px solid var(--menu-subheader-text) !important;
}

.app-tooltip.place-bottom::after {
  border-bottom: 12px solid var(--menu-subheader-text) !important;
}

.app-tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.app-tooltip-small {
  box-shadow: 2px 2px 8px var(--menu-subheader-text) !important;
  border: 1px solid var(--modal-border) !important;
  background-color: var(--menu-subheader-text) !important;
  color: var(--text-normal) !important;
  font-size: 16px !important;
  line-height: 20px !important;
  pointer-events: auto !important;
  width: 300px !important;
  max-width: 300px !important;
  white-space: pre-line;
}

.align-right {
  text-align: right;
}

table.table {
  background-color: var(--table-bg);
  border: 1px solid var(--table-border);
  border-radius: 3px;
  margin-bottom: 8px;
  table-layout: fixed;
  position: relative;
}

table.table.not-fixed {
  table-layout: auto;
  width: 300%;
}

table.table thead tr th {
  position: sticky;
  top: 45px;
  background-color: var(--table-bg);
  z-index: 99;
}

table.table.rule-grid thead tr th {
  position: inherit;
}

table.table thead tr th, table.table tbody tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  height: 31px;
  padding: 4px 6px;
  color: var(--table-header-text);
}

table.table thead tr th:first-child, 
table.table tbody tr td:first-child,
table.table tbody tr th:first-child {
  padding-left: 19px;
}

table.table thead tr th:last-child,
table.table tbody tr td:last-child,
table.table tbody tr th:last-child {
  padding-right: 19px;
}

table.table thead tr th.table-header {
    position: inherit;
    top: inherit;
    background-color: inherit;
    font-size: 18px;
    color: #FFF;
    font-weight: bold;
    background-color: var(--table-header-bg);
    padding-left: 8px;
}

table.table tbody {
  background-color: var(--table-bg);
}

table.table > tbody > tr{
  background-color: var(--table-bg);
}

table.table > tbody > tr:nth-child(even) {
  background-color: var(--table-alt-row-bg);
}

table.table.table-clickable-rows > tbody > tr {
  border-left: 4px solid var(--table-border);
}

table.table.table-clickable-rows>tbody.two-row-record:hover,
table.table.table-clickable-rows>tbody>tr:hover {
  cursor: default;
  background-color: var(--table-hover-row-bg);
}

table.table.table-clickable-rows>tbody.two-row-record:hover > td:first-child,
table.table.table-clickable-rows > tbody > tr:hover>td:first-child {
  border-left: 4px solid var(--table-hover-row-border);
}

table.table.table-clickable-rows>tbody.two-row-record:hover>tr {
  cursor: default;
  background-color: var(--table-hover-row-bg);
}

table.table.table-clickable-rows>tbody.two-row-record:hover>tr>td:first-child {
  border-left: 4px solid var(--table-hover-row-border);
}

table.table.table-clickable-rows>tbody.two-row-record:hover>tr>td {
  background-color: var(--table-hover-row-bg);
}

table.table.table-clickable-rows table.detail-table tbody tr:hover {
  cursor: default;
  background-color: var(--table-row-bg);
}

table.table.table-clickable-rows table.detail-table tbody tr:hover td:first-child {
  border-left: 4px solid transparent;
}

table.table > tbody > tr > td {
  font-size: 15px;
  line-height: 20px;
  padding: 14px 8px;
  color: var(--table-body-text);
  background-color: var(--table-bg);
}

table.table > tbody > tr:nth-child(even) td {
  background-color: var(--table-alt-row-bg);
}

table.table tbody tr td.force-wrap, table.table tbody tr td.force-wrap button {
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table.table .table-action-btn {
  width: 40px;
  text-align: center;
  vertical-align: top;
}

table.table tbody tr:hover td.table-action-btn button {
  opacity: 1;
}

table.table tbody td div.form-group {
  margin-top: 0;
}

table.table tbody td div.form-group div.field {
  margin-top: 0;
}

table.table tbody td div.form-group label {
  display: none;
}

/* Detail table */
table.detail-table {
  padding: 0;
  margin: 0;
}

table.detail-table tbody tr td:first-child,
table.detail-table tbody tr th:first-child {
  padding-left: 0;
}

table.detail-table tbody tr td:last-child,
table.detail-table tbody tr th:last-child {
  padding-right: 0;
}

table.detail-table tbody tr:nth-child(even) {
  background-color: var(--table-row-bg);
}

table > tbody > tr > td.dense-field {
  background-color: var(--table-row-bg);
  width: 100%;
  padding: 8px;
}

table>tbody>tr>td.dense-field.alt-row {
  background-color: var(--table-alt-row-bg);
}

table>tbody>tr>td.dense-field tbody,
table>tbody>tr>td.dense-field tbody tr,
table>tbody>tr>td.dense-field tbody tr td {
  background-color: transparent;
}

table>tbody>tr>td.dense-field.alt-row:hover,
table>tbody>tr>td.dense-field:hover tbody,
table>tbody>tr>td.dense-field:hover tbody tr,
table>tbody>tr>td.dense-field:hover tbody tr td {
  background-color: var(--table-hover-row-bg);
}

table.table>tbody>tr.normal-row,
table.table > tbody > tr.normal-row td {
  background-color: var(--table-row-bg);
}

table.table>tbody>tr.alt-row,
table.table > tbody > tr.alt-row td {
  background-color: var(--table-alt-row-bg);
}

/* Rule table */
table.table.rule-grid tbody td input, 
table.table.rule-grid tbody td input.form-control, 
table.table.rule-grid tbody td select,
table.table.rule-grid tbody td textarea {
  font-size: 14px;
}

div.form-group {
  margin-top: 13px;
}

div.form-group div.field {
  margin-top: 6px;
  color: var(--input-text);
}

div.form-group div.field.force-wrap, h4.force-wrap,
p.force-wrap {
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.force-wrap {
  display: inline-block;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

label {
  color: var(--label-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

input, input.form-control {
  color: var(--input-text);
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px;
}

select,
select.form-select {
  color: var(--input-text);
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 13px 10px 12px 10px;
}

.entity-name {
  font-size: 12px;
  color: var(--text-medium);
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 5px;
}

.option-member-name {
  font-size: 15px;
  color: var(--text-medium);
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 10px;
}

.normal-case {
  text-transform: none;
}

input:focus,
input.form-control:focus,
select:focus,
select.form-select:focus,
input:active,
input.form-control:active,
select:active,
select.form-select:active {
  color: var(--input-text);
  background-color: var(--input-bg);
}

i.material-icons {
  vertical-align: middle;
}

span.material-icons.disabled {
  color: var(--text-verylight);
  cursor: default;
}

div.Toastify__toast {
  border-left: 6px solid var(--notify-danger);
}

div.Toastify__toast h4 {
  color: var(--text-normal);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

div.Toastify__toast p {
  margin: 0;
  padding: 0;
  line-height: 20px;
}

div.Toastify__toast--error {
  border-color: var(--notify-danger);
}

div.Toastify__toast--warning {
  border-color: var(--notify-warning);
}

div.Toastify__toast--success {
  border-color: var(--notify-success);
}

div.Toastify__toast--info {
  border-color: var(--notify-info);
}

div.Toastify__toast-icon {
  display: none;
}

div.Toastify__progress-bar--error, 
div.Toastify__progress-bar--warning, 
div.Toastify__progress-bar--info, 
div.Toastify__progress-bar--success {
  background-color: var(--notify-progressbar);
}

div.modal-dialog {
  box-shadow: 2px 2px 15px var(--shadow-1);
  border: 1px solid var(--modal-border);
  border-radius: 8px;
}

div.modal-dialog div.modal-body, 
div.modal-dialog div.modal-footer {
  background-color: var(--modal-body-bg);
  color: var(--text-normal);
}

div.modal-dialog div.modal-header {
  height: 48px;
  background-color: var(--modal-header-bg);
  border-bottom-color: var(--elevated-border2);
}

div.modal-dialog div.modal-header div.modal-title {
  color: var(--modal-header-text);
  font-size: 20px;
  display: flex;
  align-items: center;
}

div.modal-dialog div.modal-header div.modal-title span {
  margin-left: 3px;
}

div.modal-dialog div.modal-footer {
  height: 56px;
  border-top: 0;
  padding: 8px;
}

.modal-dialog-full-width {
  width: 90vw;
  max-width: 90vw;
}

.modal-dialog-large-width {
  width: 70vw;
  max-width: 70vw;
}

div.flex-row-with-wrap, 
h1.flex-row-with-wrap,
h2.flex-row-with-wrap,
h3.flex-row-with-wrap,
h4.flex-row-with-wrap {
  color: var(--text-dark);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
}

div.flex-row-with-wrap-and-justify {
  color: var(--text-dark);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  justify-content: space-between;
}

div.flex-row-without-wrap {
  display: flex;
  align-items: center;
}

div.home-chart-tooltip {
  padding: 8px;
  box-shadow: 4px 4px 10px var(--shadow-1);
  border-radius: 6px;
  font-size: 16px;
  color: var(--text-normal);
  line-height: 20px;
}

div.overflow-x-scroll {
  overflow-x: scroll;
}

div.rule-editor {
  display: inline-block;
  min-height: 42px;
  height: 42px;
  overflow-y: auto;
  width: 100%;
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
  color: var(--input-text);
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 0;
  font-weight: 400;
  line-height: 18px;
  padding: 10px;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
}

div.rule-editor span.joinop {
  color: var(--ruleparser-purple);
  font-weight: bold;
  text-transform: uppercase;
}

div.rule-editor span.condtype {
  color: var(--ruleparser-darkgray);
  font-weight: bold;
  text-transform: lowercase;
}

div.rule-editor span.operatortype {
  color: var(--ruleparser-blue);
  font-weight: bold;
  text-transform: lowercase;
}

div.rule-editor span.condvalue {
  color: var(--ruleparser-green);
  font-weight: bold;
}

div.rule-editor span.symbol {
  color: var(--ruleparser-red);
  font-weight: bold;
  text-transform: uppercase;
}

div.rule-editor span.delim {
  color: var(--ruleparser-darkgray);
  font-weight: bold;
}

div.rule-editor span.typespec {
  display: inline-block;
  color: #FFF;
  background-color: var(--ruleparser-gray);
  font-weight: bold;
  text-transform: lowercase;
  padding: 0 4px;
  line-height: 16px;
  border-radius: 3px;
}

div.rule-editor span.typespecvalue {
  color: var(--ruleparser-green);
  font-weight: bold;
}

div.rule-editor span.condition {
  font-style: italic;
}

div.rule-editor span.function {
  display: inline-block;
  color: #FFF;
  background-color: var(--ruleparser-blue);
  font-weight: bold;
  padding: 0 4px;
  line-height: 16px;
  border-radius: 3px;
}

div.rule-editor span.ruletable {
  color: var(--ruleparser-yellow);
  font-weight: bold;
}

div.rule-editor span.handlebar {
  font-weight: normal;
  font-style: normal;
  color: var(--ruleparser-gray);
}

div.rule-editor span.handlebarcontent {
  font-weight: bold;
  font-style: italic;
  color: var(--ruleparser-blue);
}

div.rule-editor span.handlebarfunction {
  color: var(--ruleparser-green);
}

div.rule-summary {
  width: 100%;
  padding: 10px 20px 10px 0;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
  color: var(--input-text);
  font-weight: 400;
  line-height: 20px;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
}

div.rule-summary div {
  width: 100%;
  margin-left: 38px;
  text-indent: -38px;
}

div.rule-summary span.keyword {
  color: var(--ruleparser-gray);
  font-weight: bold;
  text-transform: lowercase;
}

div.rule-summary span.operator {
  color: var(--ruleparser-gray);
    font-weight: bold;
    text-transform: lowercase;
}

div.rule-summary span.text {
  color: var(--ruleparser-purple);
  font-weight: normal;
  font-size: 18px;
  font-family: "Source Sans Pro";
}

div.rule-summary span.newline:before {
  content: '\A';
}

div.rule-summary span.field {
  color: #FFF;
  font-size: 15px;
  background-color: var(--ruleparser-gray);
  font-weight: normal;
  text-transform: uppercase;
  padding: 0 4px;
  line-height: 16px;
  border-radius: 3px;
  opacity: .8;
  cursor: default;
}

div.rule-summary span.proplink {
  color: #FFF;
  font-size: 16px;
  background-color: var(--ruleparser-purple);
  font-weight: normal;
  padding: 0 4px;
  line-height: 16px;
  border-radius: 3px;
  opacity: .8;
  cursor: pointer;
}

div.rule-summary span.proplink:hover {
  background-color: var(--ruleparser-blue);
  cursor: pointer;
  opacity: 1;
}

div.rule-summary a.link, div.rule-summary span.link {
  color: var(--ruleparser-purple);
  font-weight: normal;
}

div.rule-summary span.name {
  color: var(--text-normal);
  font-weight: normal;
}

div.rule-summary span.number {
  color: var(--ruleparser-purple);
  font-weight: bold;
}

span.summary-field {
  color: #FFF;
  font-size: 14px;
  background-color: var(--ruleparser-gray);
  font-weight: normal;
  text-transform: lowercase;
  padding: 0 4px;
  line-height: 15px;
  border-radius: 3px;
  opacity: .7;
  margin-left: 5px;
}

span.list-number {
  display: inline-block;
  width: 20px;
  text-align: right;
  margin-right: 5px;
}

div.react-datepicker, 
div.react-datepicker__day, 
div.react-datepicker__month-container,
div.react-datepicker__current-month,
div.react-datepicker__day-name,
div.react-datepicker__header,
div.react-datepicker__today-button,
div.react-datepicker__input-time-container,
div.react-datepicker-time__input
 {
  background-color: var(--input-bg);
  color: var(--input-text);
}

div.react-datepicker__day--selected, 
div.react-datepicker__day:hover {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
}

div.react-datepicker__day--today {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
}

span.drag-chip-icon {
  color: var(--drag-chip-icon);
}

span.icon-with-hover {
  color: var(--drag-chip-icon);
}

span.icon-with-hover:hover {
  color: var(--link);
}

span.icon-with-hover:active {
  color: var(--link-active);
}

span.button-icon {
  color: var(--button-primary-border);
  cursor: pointer;
}

span.button-icon:hover {
  color: var(--link);
}
span.button-icon:active {
  color: var(--link-active);
}

span.icon-with-hover.disabled {
  color: var(--text-verylight);
  pointer-events: none;
}

    @-webkit-keyframes rotating
  
    /* Safari and Chrome */
      {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
  
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
  
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  
    .rotating {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }

    @keyframes pulseFadeAnimation {
      0% {
        opacity: 1;
      }    
      50% {
        opacity: 0.2;
      }    
      100% {
        opacity: 1;
      }
    }

    @-o-keyframes pulseFadeAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.2;
      }

      100% {
        opacity: 1;
      }
    }

    @-moz-keyframes pulseFadeAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.2;
      }

      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes pulseFadeAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.2;
      }

      100% {
        opacity: 1;
      }
    }

    .pulse-fade {
      -webkit-animation: pulseFadeAnimation 2s infinite;
      -moz-animation: pulseFadeAnimation 2s infinite;
      -o-animation: pulseFadeAnimation 2s infinite;
      animation: pulseFadeAnimation 2s infinite;
    }

        @keyframes pulseFadeAnimationSubtle {
          0% {
            opacity: 1;
          }
    
          50% {
            opacity: 0.5;
          }
    
          100% {
            opacity: 1;
          }
        }
    
        @-o-keyframes pulseFadeAnimationSubtle {
          0% {
            opacity: 1;
          }
    
          50% {
            opacity: 0.5;
          }
    
          100% {
            opacity: 1;
          }
        }
    
        @-moz-keyframes pulseFadeAnimationSubtle {
          0% {
            opacity: 1;
          }
    
          50% {
            opacity: 0.5;
          }
    
          100% {
            opacity: 1;
          }
        }
    
        @-webkit-keyframes pulseFadeAnimationSubtle {
          0% {
            opacity: 1;
          }
    
          50% {
            opacity: 0.5;
          }
    
          100% {
            opacity: 1;
          }
        }

    .pulse-fade-subtle {
      -webkit-animation: pulseFadeAnimationSubtle 3s infinite;
      -moz-animation: pulseFadeAnimationSubtle 3s infinite;
      -o-animation: pulseFadeAnimationSubtle 3s infinite;
      animation: pulseFadeAnimationSubtle 3s infinite;
    }

    @keyframes pulseFadeStayVisibleAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: .5;
      }

      100% {
        opacity: 1;
      }
    }

    @-o-keyframes pulseFadeStayVisibleAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: .5;
      }

      100% {
        opacity: 1;
      }
    }

    @-moz-keyframes pulseFadeStayVisibleAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: .5;
      }

      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes pulseFadeStayVisibleAnimation {
      0% {
        opacity: 1;
      }

      50% {
        opacity: .5;
      }

      100% {
        opacity: 1;
      }
    }

    .pulse-fade-stay-visible {
      -webkit-animation: pulseFadeStayVisibleAnimation 2s infinite;
      -moz-animation: pulseFadeStayVisibleAnimation 2s infinite;
      -o-animation: pulseFadeStayVisibleAnimation 2s infinite;
      animation: pulseFadeStayVisibleAnimation 2s infinite;
    }

    div.animatewaiting-appear, div.animatewaiting-enter {
      opacity: 0;
    }

    div.animatewaiting-appear-active, div.animatewaiting-enter-active {
      opacity: 1;
      transition: all 2000ms ease-in;
    }

    .heartbeat {
      position: relative;
      display: inline-block;
      transform-origin: center;
      -webkit-animation: heartbeat 1.5s infinite;
      -moz-animation: heartbeat 1.5s infinite;
      -o-animation: heartbeat 1.5s infinite;
      animation: heartbeat 1.5s infinite;
    }

    @keyframes heartbeat {
      0% { transform: scale(1); }
      14% { transform: scale(1.3); }
      28% { transform: scale(1); }
      42% { transform: scale(1.3); }
      70% { transform: scale(1); }
    }

    @-o-keyframes heartbeat {
      0% { transform: scale(1); }
      14% { transform: scale(1.3); }
      28% { transform: scale(1); }
      42% { transform: scale(1.3); }
      70% { transform: scale(1); }
    }

    @-moz-keyframes heartbeat {
      0% { transform: scale(1); }
      14% { transform: scale(1.3); }
      28% { transform: scale(1); }
      42% { transform: scale(1.3); }
      70% { transform: scale(1); }
    }

    @-webkit-keyframes heartbeat {
      0% { transform: scale(1); }
      14% { transform: scale(1.3); }
      28% { transform: scale(1); }
      42% { transform: scale(1.3); }
      70% { transform: scale(1); }
    }