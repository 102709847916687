
div.loader-wrapper {
  height: 50%;
  text-align: center;
  line-height: 50vh;
}

div.loader-wrapper h2 {
  margin-top: 0;
  transform: scale(1.5);
}

.DNA_cont {
  display: inline-block;
  position: relative;
  transform: scale(0.50);
}

.nucleobase {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.nucleobase:not(:last-child) {
  margin-right: 3.86vh;
}

.nucleobase:before,
.nucleobase:after {
  content: "";
  display: inline-block;
  width: 3vh;
  height: 3vh;
  border-radius: 50%;
  position: absolute;
}

.nucleobase:nth-child(1) {
  -webkit-animation-delay: -1.869s;
  animation-delay: -1.869s;
}

.nucleobase:nth-child(1):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -1.869s;
  animation-delay: -1.869s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(1):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -1.869s;
  animation-delay: -1.869s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(2) {
  -webkit-animation-delay: -3.738s;
  animation-delay: -3.738s;
}

.nucleobase:nth-child(2):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -3.738s;
  animation-delay: -3.738s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(2):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -3.738s;
  animation-delay: -3.738s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(3) {
  -webkit-animation-delay: -5.607s;
  animation-delay: -5.607s;
}

.nucleobase:nth-child(3):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -5.607s;
  animation-delay: -5.607s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(3):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -5.607s;
  animation-delay: -5.607s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(4) {
  -webkit-animation-delay: -7.476s;
  animation-delay: -7.476s;
}

.nucleobase:nth-child(4):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -7.476s;
  animation-delay: -7.476s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(4):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -7.476s;
  animation-delay: -7.476s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(5) {
  -webkit-animation-delay: -9.345s;
  animation-delay: -9.345s;
}

.nucleobase:nth-child(5):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -9.345s;
  animation-delay: -9.345s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(5):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -9.345s;
  animation-delay: -9.345s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(6) {
  -webkit-animation-delay: -11.214s;
  animation-delay: -11.214s;
}

.nucleobase:nth-child(6):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -11.214s;
  animation-delay: -11.214s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(6):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -11.214s;
  animation-delay: -11.214s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(7) {
  -webkit-animation-delay: -13.083s;
  animation-delay: -13.083s;
}

.nucleobase:nth-child(7):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -13.083s;
  animation-delay: -13.083s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(7):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -13.083s;
  animation-delay: -13.083s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(8) {
  -webkit-animation-delay: -14.952s;
  animation-delay: -14.952s;
}

.nucleobase:nth-child(8):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -14.952s;
  animation-delay: -14.952s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(8):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -14.952s;
  animation-delay: -14.952s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(9) {
  -webkit-animation-delay: -16.821s;
  animation-delay: -16.821s;
}

.nucleobase:nth-child(9):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -16.821s;
  animation-delay: -16.821s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(9):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -16.821s;
  animation-delay: -16.821s;
  background-color: var(--spinner-light);
}

.nucleobase:nth-child(10) {
  -webkit-animation-delay: -18.69s;
  animation-delay: -18.69s;
}

.nucleobase:nth-child(10):before {
  -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -18.69s;
  animation-delay: -18.69s;
  background-color: var(--spinner-dark2);
}

.nucleobase:nth-child(10):after {
  -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  -webkit-animation-delay: -18.69s;
  animation-delay: -18.69s;
  background-color: var(--spinner-light);
}

@-webkit-keyframes animBefore {
  0% {
    top: -6vh;
    z-index: 1;
  }

  25% {
    transform: scale(1.2);
    z-index: 1;
  }

  50% {
    top: 6vh;
    z-index: -1;
  }

  75% {
    background-color: var(--spinner-light2);
    transform: scale(0.8);
    z-index: -1;
  }

  100% {
    top: -6vh;
    z-index: -1;
  }
}

@keyframes animBefore {
  0% {
    top: -6vh;
    z-index: 1;
  }

  25% {
    transform: scale(1.2);
    z-index: 1;
  }

  50% {
    top: 6vh;
    z-index: -1;
  }

  75% {
    background-color: var(--spinner-light2);
    transform: scale(0.8);
    z-index: -1;
  }

  100% {
    top: -6vh;
    z-index: -1;
  }
}

@-webkit-keyframes animAfter {
  0% {
    top: 6vh;
    z-index: -1;
  }

  25% {
    background-color: var(--spinner-dark);
    transform: scale(0.8);
    z-index: -1;
  }

  50% {
    top: -6vh;
    z-index: 1;
  }

  75% {
    transform: scale(1.2);
    z-index: 1;
  }

  100% {
    top: 6vh;
    z-index: 1;
  }
}

@keyframes animAfter {
  0% {
    top: 6vh;
    z-index: -1;
  }

  25% {
    background-color: var(--spinner-dark);
    transform: scale(0.8);
    z-index: -1;
  }

  50% {
    top: -6vh;
    z-index: 1;
  }

  75% {
    transform: scale(1.2);
    z-index: 1;
  }

  100% {
    top: 6vh;
    z-index: 1;
  }
}

@-webkit-keyframes animDotBar {
  0% {
    height: 8.25vh;
  }

  25% {
    height: 0;
  }

  50% {
    height: 8.25vh;
  }

  75% {
    height: 0;
  }

  100% {
    height: 8.25vh;
  }
}

@keyframes animDotBar {
  0% {
    height: 8.25vh;
  }

  25% {
    height: 0;
  }

  50% {
    height: 8.25vh;
  }

  75% {
    height: 0;
  }

  100% {
    height: 8.25vh;
  }
}

@-webkit-keyframes superscript {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }

  100% {
    opacity: 1;
    transform: translateY(0em);
  }
}

@keyframes superscript {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }

  100% {
    opacity: 1;
    transform: translateY(0em);
  }
}